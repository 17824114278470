import React from 'react';
import Loadable from 'react-loadable';
import { LoadingPage } from '@/components/loading/loadingBase';
import HeaderMetadata from '@/layouts/HeaderMetadata';
const TermsAndConditionsLoadable = Loadable({
  loader: () => import('@/sections/TermsAndConditions'),
  loading: (props) => <LoadingPage isLoading={true} error={props.error} />,
});
const TermsAndConditionsPage: React.FC = () => {
  return (
    <React.Fragment>
      <HeaderMetadata title="Terms And Conditions" />
      <TermsAndConditionsLoadable />
    </React.Fragment>
  );
};

export default TermsAndConditionsPage;
